// globals
html,
body {
  height: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.footer {
  width: 100%;
  padding: 0 1rem;
  /* Set the fixed height of the footer here */
  height: 28px;
  line-height: 28px; /* Vertically center the text there */
  color: $white;
  background-color: $gray-600;
  font-size: 80%;
}

.footer a {
  color: $white;
}

.content,
.footer {
  flex-shrink: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $blue-dark;
}

form .container {
  max-width: map-get($container-max-widths, "lg");
}

.table {
  thead {
    color: $white;
    background-color: $cyan;
  }
}

.btn-rounded {
  border-radius: 35px !important;
}

.no-left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.no-right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.font-weight-less-bold {
  font-weight: 500 !important;
}

.cursor-help {
  cursor: help !important;
}

// components
.avatar {
  width: 2rem;
  height: 2rem;
}

.release-notes {
  .header {
    color: $blue !important;
    font-weight: $font-weight-light;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-800;
  }

  margin-bottom: 4rem;
}

.issues-column {
  background-color: $gray-200;
}

.saving-spinner {
  width: 3rem;
  height: 3rem;
}

// 3rd parties
// reactstrap
.navbar-light .navbar-brand {
  color: $blue-dark;
}

.nav-tabs {
  border-bottom: 2px solid $blue-light;

  .nav-item {
    margin-right: 2 * $nav-link-padding-x;
    padding: 0.5rem 0.25rem;

    &.active {
      border-bottom: 4px solid $blue-dark;
    }
  }

  .nav-link {
    color: $blue;
    font-weight: $headings-font-weight;
    padding: 0;
    margin: 0;
    border: 0;

    &.active {
      color: $blue-dark;
    }
  }
}

// Cursor is flickering at the edge of tooltip: https://github.com/reactstrap/reactstrap/issues/1728
.tooltip {
  pointer-events: none;
}

// fix invalid-feedback not displaying
.field-wrapper.is-invalid ~ .invalid-feedback {
  display: block;
}

// react-date-picker
.react-datepicker-wrapper {
  display: block !important;
  flex-grow: 1 !important;
}

// fix the right boarder
.react-datepicker__time-container {
  box-sizing: content-box !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

// customized react-select, requires classNamePrefix=react-select
// copy of form-control { &:focus }
.react-select__control--is-focused {
  color: $input-focus-color !important;
  background-color: $input-focus-bg !important;
  border-color: $input-focus-border-color !important;
  outline: 0 !important;
  // Avoid using mixin so we can pass custom focus shadow properly
  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow !important;
  } @else {
    box-shadow: $input-focus-box-shadow !important;
  }
}

// auth0-lock
.auth0-lock-cred-pane-internal-wrapper {
  height: auto !important;
}
